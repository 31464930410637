<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="5">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.ref"
                    label="Reference"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.label"
                    label="Nom Equipe"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Nom Equipe obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              ref="save"
              color="blue darken-1"
              text
              @click="save"
              v-if="!readonly"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_INVENT_EQUIPE from "../graphql/Inventaire/CREATE_INVENT_EQUIPE.gql";
import UPDATE_INVENT_EQUIPE from "../graphql/Inventaire/UPDATE_INVENT_EQUIPE.gql";

export default {
  components: {},
  name: "invdetailform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    inv_id: Number,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Ajout Equipe  "
          : "Equipe : ".concat(this.editedItem.label);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v;
        if (this.editedItem.id > 0) {
          v = {
            invequipe: {
              id: this.editedItem.id,
              label: this.editedItem.label,
              ref: this.editedItem.ref,
              write_uid: this.$store.state.me.id,
            },
          };

          this.maj(UPDATE_INVENT_EQUIPE, v);
          this.items.splice(this.editedItem.index, 1, this.editedItem);
        } else {
          v = {
            invequipe: {
              inv_id: this.inv_id,
              label: this.editedItem.label,
              ref: this.editedItem.ref,
              write_uid: this.$store.state.me.id,
              create_uid: this.$store.state.me.id,
            },
          };
          this.editedItem.nbagent = 0;
          let r = await this.maj(CREATE_INVENT_EQUIPE, v);
          if (r) this.editedItem.id = r.createInvEquipe.id;
          this.items.push(this.editedItem);
        }
      }
    },
  },
};
</script>
